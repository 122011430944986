export const handleDateAvailability = (
  calendarData = [],
  startDate,
  endDate
) => {
  if (!Array.isArray(calendarData)) {
    return false;
  }

  const checkin = new Date(startDate);
  const checkout = new Date(endDate);

  let selectedDates = [];
  let currentDate = new Date(checkin);

  while (currentDate < checkout) {
    selectedDates.push(currentDate.toISOString().split("T")[0]); // Format YYYY-MM-DD
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }
  // Check if all selected dates exist in the calendar with "available" status
  return selectedDates?.every((date) =>
    calendarData?.some(
      (item) => item?.date === date && item?.status === "available"
    )
  );
};
