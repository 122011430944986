import React, { useContext, useState } from "react";
import "../Styles/pricingCardStyle.css";
import axios from "axios";
import { DataContext } from "../Context/Context";

const PricingTable = ({
  price,
  cleaningFee,
  nights,
  additional,
  bedLineFee,
  cityTourismTax,
  occupancyTax,
  vatGstTax,
  weeklyDiscount,
  onError,
}) => {
  const { setDiscountValue } = useContext(DataContext);
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [couponError, setCouponError] = useState("");
  const [couponSuccess, setCouponSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  // Calculate base price without 5% markup
  const basePrice = price || 0;
  const markupPrice = basePrice * 1.05; // Add 5% markup
  const totalAfterDiscount = markupPrice - discount;

  const adsOnServices = additional;

  // Update the total calculation to include weekly discount
  const total =
    (cleaningFee ?? 0) +
    (adsOnServices ?? 0) +
    (bedLineFee ?? 0) +
    (cityTourismTax ?? 0) +
    (occupancyTax ?? 0) +
    (vatGstTax ?? 0) +
    (totalAfterDiscount ?? 0) -
    (weeklyDiscount ?? 0);

  // Enhanced coupon validation with real-time check
  const applyCoupon = async () => {
    setLoading(true);
    setCouponError("");
    setCouponSuccess("");

    try {
      // Fetch access token (replace with your endpoint)
      const tokenResponse = await axios.post(
        "https://365luxuryhomes.com/api/accessToken"
      );
      const accessToken = tokenResponse.data.access_token;

      // Fetch latest coupons
      const response = await axios.get("https://api.hostaway.com/v1/coupons", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Cache-Control": "no-cache",
        },
      });

      const coupons = response.data.result; // Access the result array

      // Validate the coupon
      const matchedCoupon = coupons.find(
        (c) => c.name.toLowerCase() === couponCode.toLowerCase()
      );

      if (!matchedCoupon) {
        throw new Error("Invalid coupon code");
      }

      if (!matchedCoupon.isActive || matchedCoupon.isExpired) {
        throw new Error("Coupon is inactive or expired");
      }

      // Check minimum nights
      if (nights < matchedCoupon.minimumNights) {
        throw new Error(
          `This coupon requires a minimum stay of ${matchedCoupon.minimumNights} nights.`
        );
      }

      // Calculate discount on base price only
      let calculatedDiscount = 0;
      if (matchedCoupon.type === "percentage") {
        calculatedDiscount = (basePrice * matchedCoupon.amount) / 100;
      } else if (matchedCoupon.type === "flatFee") {
        calculatedDiscount = matchedCoupon.amount;
      }

      setDiscountValue(calculatedDiscount);
      setDiscount(calculatedDiscount);
      setCouponSuccess("Coupon applied successfully!");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setCouponError(errorMessage);
      setDiscount(0);
      if (onError) {
        onError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const generateTitle = () => {
    if (nights >= 30) {
      return "Monthly Discount (20%)";
    } else if (nights >= 7) {
      return "Weekly Discount (10%)";
    }
    return "";
  };

  return (
    <div className="pricing-table-container">
      <table className="pricing-table w-full">
        <tbody>
          <tr className="text-[#aca9a5]">
            <td className="text-[14px] font-thin">Base Price</td>
            <td className="text-[14px] font-thin">
              AED {totalAfterDiscount.toFixed(2)}
            </td>
          </tr>
          {weeklyDiscount > 0 && (
            <tr className="text-red-500">
              <td className="text-[14px] font-thin">{generateTitle()}</td>
              <td className="text-[14px] font-thin">
                - AED {weeklyDiscount.toFixed(2)}
              </td>
            </tr>
          )}
          <tr className="text-[#aca9a5]">
            <td className="text-[14px] font-thin">Stay Fee</td>
            <td className="text-[14px] font-thin">
              AED {cleaningFee + bedLineFee + occupancyTax}
            </td>
          </tr>
          <tr className="text-[#aca9a5]">
            <td className="text-[14px] font-thin">Total Nights</td>
            <td className="text-[14px] font-thin">{nights}</td>
          </tr>
          <tr className="text-[#aca9a5]">
            <td className="text-[14px] font-thin">City/Tourism Tax</td>
            <td className="text-[14px] font-thin">AED {cityTourismTax}</td>
          </tr>
          <tr className="text-[#aca9a5]">
            <td className="text-[14px] font-thin">VAT/GST</td>
            <td className="text-[14px] font-thin">
              AED {vatGstTax ? vatGstTax.toFixed(2) : "0.00"}
            </td>
          </tr>
          {adsOnServices > 0 && adsOnServices && (
            <tr className="text-[#aca9a5]">
              <td className="text-[14px] font-thin">Adds-on Services</td>
              <td className="text-[14px] font-thin">AED {adsOnServices}</td>
            </tr>
          )}
          {discount > 0 && (
            <tr className="text-[#aca9a5]">
              <td className="text-[14px] font-thin text-[#aca9a5]">
                Coupon Applied
              </td>
              <td className="text-[14px] font-thin text-[#aca9a5]">
                - AED {discount.toFixed(2)}
              </td>
            </tr>
          )}
          <tr className="text-[#aca9a5]">
            <td className="text-[14px] font-bold">Total</td>
            <td className="text-[14px] font-bold">AED {total.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>

      {/* Coupon Input Section */}
      <div className="coupon-container mt-4">
        <div className="flex items-center">
          <input
            type="text"
            placeholder="Enter Coupon Code"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            className="input input-sm w-full input-bordered focus:outline-none text-[15px] text-[#aca9a5] rounded-sm"
          />
          <button
            onClick={applyCoupon}
            className="btn btn-sm rounded-sm bg-[#aca9a5] text-white"
          >
            {loading ? (
              <span className="loading loading-dots loading-md"></span>
            ) : (
              "Apply"
            )}
          </button>
        </div>
        {couponError && (
          <p className="error-message text-[#aca9a5] text-[14px] font-thin mt-2">
            {couponError}
          </p>
        )}
        {couponSuccess && (
          <p className="success-message text-[#aca9a5] text-[14px] font-thin mt-2">
            {couponSuccess}
          </p>
        )}
      </div>
    </div>
  );
};

export default PricingTable;
