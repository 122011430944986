import React, { useEffect, useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import axios from "axios";

// Lazy load non-critical components
const CustomDateRangePicker = lazy(() =>
  import("../../Components/CustomDateRangePicker")
);

const Banner = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState("");
  const [visible, setVisible] = useState(false);
  const [guestCounts, setGuestCounts] = useState({
    guest: 0,
    child: 0,
    infants: 0,
  });
  const [section, setSection] = useState({
    imageUrl:
      "https://luxury-homes-365.s3.eu-north-1.amazonaws.com/sections/1736081506927_image2.jpg",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Preload the background image
    const img = new Image();
    img.src = section.imageUrl;

    fetchSection();
  }, []);

  const fetchSection = async () => {
    try {
      const response = await axios.get(
        "https://365luxuryhomes.com/api/sections/home_page"
      );
      if (response.data?.imageUrl) {
        setSection(response.data);
      }
    } catch (error) {
      // Handle error silently
    }
  };

  const handleCounter = (value, type) => {
    setGuestCounts((prev) => ({
      ...prev,
      [type]: value === "plus" ? prev[type] + 1 : Math.max(0, prev[type] - 1),
    }));
  };

  const getGuestDisplayText = () => {
    const { guest, child, infants } = guestCounts;
    if (!guest && !child && !infants) return "";

    return [
      guest > 0 ? `${guest} Guest` : "",
      child > 0 ? `${child} Child` : "",
      infants > 0 ? `${infants} Infants` : "",
    ]
      .filter(Boolean)
      .join(", ");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/listings`);
  };

  return (
    <section
      className="home_banner relative"
      style={{
        backgroundImage: `url(${section.imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {loading && (
        <div className="skeleton h-full w-full absolute top-0 left-0 -z-0"></div>
      )}
      <div className="container h-[100%]">
        <div className="h-[100%] flex lg:items-end md:items-end items-end justify-center">
          <form
            onSubmit={handleSubmit}
            className="p-6 bg-white lg:flex md:flex hidden items-center gap-4 mb-5 rounded-lg lg:mt-0 md:mt-0 mt-32"
          >
            <div className="flex lg:flex-row md:flex-row flex-col items-end justify-center gap-4">
              <div>
                <select
                  value={location}
                  required
                  onChange={(e) => setLocation(e.target.value)}
                  className="select border-b-2 min-w-[250px] text-[#aca9a5] border-b-[#aca9a5] h-[50px] rounded-none w-full max-w-lg focus:outline-0 focus:border-b-2 focus:border-b-[#aca9a5]"
                >
                  <option disabled selected>
                    Where do you want to stay?
                  </option>
                  <option value={"palm_jumeirah"}>Palm Jumeirah</option>
                  <option value={"dubai_marina"}>Dubai Marina</option>
                  <option value={"downtown"}>Downtown</option>
                  <option value={"bluewaters_island"}>Bluewaters Island</option>
                  <option value={"j_b_r"}>
                    Jumeirah Beach Residence (JBR)
                  </option>
                  <option value={""}>Dubai</option>
                </select>
              </div>
              <Suspense fallback={<div>Loading...</div>}>
                <CustomDateRangePicker />
              </Suspense>
              <div className="relative lg:w-auto md:w-auto w-full rounded-md h-[52px]">
                <input
                  type="text"
                  name=""
                  placeholder="Select Guests"
                  id="guest-field"
                  value={getGuestDisplayText()}
                  onClick={() => setVisible(!visible)}
                  className="block min-w-[140px] text-[14px] text-[#aca9a5] w-full h-[52px] max-w-sm px-3 py-2 border border-gray-300 focus:outline-none focus:ring-[#aca9a5] placeholder:text-[#aca9a5] focus:border-[#aca9a5] rounded-md"
                />

                {visible && (
                  <div
                    id="guest-counter"
                    className="bg-white p-4 shadow-md absolute top-14 w-60 rounded-lg z-20"
                  >
                    <div className="flex flex-col gap-4">
                      <div className="flex items-center justify-between gap-2">
                        <div>
                          <h3 className="text-[14px] leading-none font-medium text-[#aca9a5]">
                            Adult
                          </h3>
                          <span className="text-xs text-[#aca9a5]">
                            Ages 13 or above
                          </span>
                        </div>
                        <div className="flex items-center justify-center gap-2">
                          <div
                            onClick={() => handleCounter("minus", "guest")}
                            className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={20}
                              height={20}
                              className="text-[#aca9a5]"
                              fill={"none"}
                            >
                              <path
                                d="M20 12L4 12"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <span className="text-[#aca9a5]">
                            {guestCounts.guest}
                          </span>
                          <div
                            onClick={() => handleCounter("plus", "guest")}
                            className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={20}
                              height={20}
                              className="text-[#aca9a5]"
                              fill={"none"}
                            >
                              <path
                                d="M12 4V20"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4 12H20"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-between gap-2">
                        <div>
                          <h3 className="text-[14px] leading-none font-medium text-[#aca9a5]">
                            Children
                          </h3>
                          <span className="text-xs text-[#aca9a5]">
                            Ages 2 to 12
                          </span>
                        </div>
                        <div className="flex items-center justify-center gap-2">
                          <div
                            onClick={() => handleCounter("minus", "child")}
                            className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={20}
                              height={20}
                              className="text-[#aca9a5]"
                              fill={"none"}
                            >
                              <path
                                d="M20 12L4 12"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <span className="text-[#aca9a5]">
                            {guestCounts.child}
                          </span>
                          <div
                            onClick={() => handleCounter("plus", "child")}
                            className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={20}
                              height={20}
                              className="text-[#aca9a5]"
                              fill={"none"}
                            >
                              <path
                                d="M12 4V20"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4 12H20"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center justify-between gap-2">
                        <div>
                          <h3 className="text-[14px] leading-none font-medium text-[#aca9a5]">
                            Infants
                          </h3>
                          <span className="text-xs text-[#aca9a5]">
                            Under 2 years
                          </span>
                        </div>
                        <div className="flex items-center justify-center gap-2">
                          <div
                            onClick={() => handleCounter("minus", "infants")}
                            className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={20}
                              height={20}
                              className="text-[#aca9a5]"
                              fill={"none"}
                            >
                              <path
                                d="M20 12L4 12"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <span className="text-[#aca9a5]">
                            {guestCounts.infants}
                          </span>
                          <div
                            onClick={() => handleCounter("plus", "infants")}
                            className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={20}
                              height={20}
                              className="text-[#aca9a5]"
                              fill={"none"}
                            >
                              <path
                                d="M12 4V20"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M4 12H20"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <span
                        onClick={() => setVisible(!visible)}
                        className="text-[#aca9a5] text-[14px] cursor-pointer mt-4 flex items-center gap-1 hover:text-red-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width={20}
                          height={20}
                          className="text-inherit"
                          fill={"none"}
                        >
                          <path
                            d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Close
                      </span>
                    </div>
                  </div>
                )}
              </div>

              {/* Submit Button */}
              <button
                id="book-btn"
                className="mb-[2px] lg:w-auto md:w-auto px-6 rounded-md w-full h-[50px] bg-[#797a76]
        text-white font-normal border-none hover:bg-gray-400 uppercase"
              >
                Book
              </button>
            </div>
          </form>
          <div
            onClick={() => document.getElementById("my_modal_3").showModal()}
            className="flex items-center gap-4 mb-8 lg:hidden md:hidden transition-all ease-in-out hover:scale-95 w-full"
          >
            <div className="w-full h-[50px] bg-white rounded-xl text-[#aca9a5] flex items-center p-4 gap-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="text-inherit"
                fill={"none"}
              >
                <path
                  d="M17.5 17.5L22 22"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex flex-col items-start">
                <span className="leading-none text-[#aca9a5]">Where to?</span>
                <span className="text-[10px] text-[#aca9a5]">
                  Anywhere • Any week • Add guests
                </span>
              </div>
            </div>
            <div className="bg-white p-3 rounded-full text-[#aca9a5]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="text-inherit"
                fill={"none"}
              >
                <path
                  d="M3 7H6"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 17H9"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 17L21 17"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 7L21 7"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 7C6 6.06812 6 5.60218 6.15224 5.23463C6.35523 4.74458 6.74458 4.35523 7.23463 4.15224C7.60218 4 8.06812 4 9 4C9.93188 4 10.3978 4 10.7654 4.15224C11.2554 4.35523 11.6448 4.74458 11.8478 5.23463C12 5.60218 12 6.06812 12 7C12 7.93188 12 8.39782 11.8478 8.76537C11.6448 9.25542 11.2554 9.64477 10.7654 9.84776C10.3978 10 9.93188 10 9 10C8.06812 10 7.60218 10 7.23463 9.84776C6.74458 9.64477 6.35523 9.25542 6.15224 8.76537C6 8.39782 6 7.93188 6 7Z"
                  stroke="currentColor"
                  strokeWidth="2"
                />
                <path
                  d="M12 17C12 16.0681 12 15.6022 12.1522 15.2346C12.3552 14.7446 12.7446 14.3552 13.2346 14.1522C13.6022 14 14.0681 14 15 14C15.9319 14 16.3978 14 16.7654 14.1522C17.2554 14.3552 17.6448 14.7446 17.8478 15.2346C18 15.6022 18 16.0681 18 17C18 17.9319 18 18.3978 17.8478 18.7654C17.6448 19.2554 17.2554 19.6448 16.7654 19.8478C16.3978 20 15.9319 20 15 20C14.0681 20 13.6022 20 13.2346 19.8478C12.7446 19.6448 12.3552 19.2554 12.1522 18.7654C12 18.3978 12 17.9319 12 17Z"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>

          {/* You can open the modal using document.getElementById('ID').showModal() method */}
          <dialog id="my_modal_3" className="modal">
            <div className="modal-box phone_modal p-4 pt-14">
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn border border-[#aca9a5] btn-sm btn-circle btn-ghost absolute right-2 top-3 text-[#aca9a5]">
                  ✕
                </button>
              </form>
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <h3 className="text-[20px] text-[#aca9a5]">Where to?</h3>
                <div>
                  <select
                    onChange={(e) => setLocation(e.target.value)}
                    className="select border-b-2 pl-0 text-[#aca9a5] border-b-[#aca9a5] h-[50px] rounded-none w-full max-w-lg focus:outline-0 focus:border-b-2 focus:border-b-[#aca9a5]"
                  >
                    <option disabled selected>
                      Where do you want to stay?
                    </option>
                    <option value={"palm_jumeirah"}>Palm Jumeirah</option>
                    <option value={"dubai_marina"}>Dubai Marina</option>
                    <option value={"downtown"}>Downtown</option>
                    <option value={"bluewaters_island"}>
                      Bluewaters Island
                    </option>
                    <option value={"j_b_r"}>
                      Jumeirah Beach Residence (JBR)
                    </option>
                    <option value={""}>Dubai</option>
                  </select>
                </div>
                <Suspense fallback={<div>Loading...</div>}>
                  <CustomDateRangePicker />
                </Suspense>
                <div className="grid grid-cols-2 gap-4">
                  <div className="relative lg:w-auto md:w-auto w-full rounded-md h-[52px]">
                    <input
                      type="text"
                      name=""
                      placeholder="Select Guests"
                      id="guest-field"
                      value={getGuestDisplayText()}
                      onClick={() => setVisible(!visible)}
                      className="block min-w-[140px] text-[14px] text-[#aca9a5] w-full h-[52px] max-w-sm px-3 py-2 border border-gray-300 focus:outline-none focus:ring-[#aca9a5] placeholder:text-[#aca9a5] focus:border-[#aca9a5] rounded-md"
                    />

                    {visible && (
                      <div
                        id="guest-counter"
                        className="bg-white p-4 shadow-md absolute top-14 w-60 rounded-lg z-20"
                      >
                        <div className="flex flex-col gap-4">
                          <div className="flex items-center justify-between gap-2">
                            <div>
                              <h3 className="text-[14px] leading-none font-medium text-[#aca9a5]">
                                Adult
                              </h3>
                              <span className="text-xs text-[#aca9a5]">
                                Ages 13 or above
                              </span>
                            </div>
                            <div className="flex items-center justify-center gap-2">
                              <div
                                onClick={() => handleCounter("minus", "guest")}
                                className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                                  className="text-[#aca9a5]"
                                  fill={"none"}
                                >
                                  <path
                                    d="M20 12L4 12"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <span className="text-[#aca9a5]">
                                {guestCounts.guest}
                              </span>
                              <div
                                onClick={() => handleCounter("plus", "guest")}
                                className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                                  className="text-[#aca9a5]"
                                  fill={"none"}
                                >
                                  <path
                                    d="M12 4V20"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 12H20"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center justify-between gap-2">
                            <div>
                              <h3 className="text-[14px] leading-none font-medium text-[#aca9a5]">
                                Children
                              </h3>
                              <span className="text-xs text-[#aca9a5]">
                                Ages 2 to 12
                              </span>
                            </div>
                            <div className="flex items-center justify-center gap-2">
                              <div
                                onClick={() => handleCounter("minus", "child")}
                                className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                                  className="text-[#aca9a5]"
                                  fill={"none"}
                                >
                                  <path
                                    d="M20 12L4 12"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <span className="text-[#aca9a5]">
                                {guestCounts.child}
                              </span>
                              <div
                                onClick={() => handleCounter("plus", "child")}
                                className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                                  className="text-[#aca9a5]"
                                  fill={"none"}
                                >
                                  <path
                                    d="M12 4V20"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 12H20"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center justify-between gap-2">
                            <div>
                              <h3 className="text-[14px] leading-none font-medium text-[#aca9a5]">
                                Infants
                              </h3>
                              <span className="text-xs text-[#aca9a5]">
                                Under 2 years
                              </span>
                            </div>
                            <div className="flex items-center justify-center gap-2">
                              <div
                                onClick={() =>
                                  handleCounter("minus", "infants")
                                }
                                className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                                  className="text-[#aca9a5]"
                                  fill={"none"}
                                >
                                  <path
                                    d="M20 12L4 12"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <span className="text-[#aca9a5]">
                                {guestCounts.infants}
                              </span>
                              <div
                                onClick={() => handleCounter("plus", "infants")}
                                className="border border-slate-400 w-[30px] h-[30px] rounded-full p-1 flex items-center justify-center cursor-pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width={20}
                                  height={20}
                                  className="text-[#aca9a5]"
                                  fill={"none"}
                                >
                                  <path
                                    d="M12 4V20"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M4 12H20"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <span
                            onClick={() => setVisible(!visible)}
                            className="text-[#aca9a5] text-[14px] cursor-pointer mt-4 flex items-center gap-1 hover:text-red-500"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width={20}
                              height={20}
                              className="text-inherit"
                              fill={"none"}
                            >
                              <path
                                d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Close
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Submit Button */}
                  <button
                    id="book-btn"
                    className="mb-[2px] lg:w-auto md:w-auto text-[16px] px-6 rounded-md w-full h-[50px] bg-[#797a76]
        text-white font-normal border-none hover:bg-gray-400 uppercase"
                  >
                    Book
                  </button>
                </div>
              </form>
            </div>
          </dialog>
        </div>
      </div>
    </section>
  );
};

export default Banner;
