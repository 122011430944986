import axios from "axios";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { fetchListingTaxSettings } from "../../Components/fetchListingTaxSettings";
import { calculateTaxes } from "../../Components/calculateTaxes";
import { DataContext } from "../../Context/Context";
import { handleDateAvailability } from "../../utilities/checkDateAvailability";

const Checkout = () => {
  const [msgtype, setMsgtype] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [tax, setTax] = useState([]);
  const { discount } = useContext(DataContext);
  const [isAvailabilityChecking, setIsAvailabilityChecking] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [totalPrices, setTotalPrices] = useState(0);
  const [calculatedPrices, setCalculatedPrices] = useState({
    markupPrice: 0,
    totalWithFees: 0,
  });

  const [weeklyDiscount, setWeeklyDiscount] = useState(null);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [calendarData, setCalendarData] = useState({});

  const [searchParams] = useSearchParams();
  const ref = searchParams.get("ref");

  const location = useLocation();
  const {
    formattedEndDate,
    formattedStartDate,
    guestCount,
    childCount,
    infantCount,
    bedLineFee,
    nights,
    updatedPrice,
    cleaningFee,
    additionalValue,
    selectedServices,
    selectedServices2,
    listing,
  } = location.state || {};

  useEffect(() => {
    const fetchCalendarForListing = async () => {
      setCalendarLoading(true);
      try {
        // Fetch access token
        const tokenResponse = await axios.post(
          "https://365luxuryhomes.com/api/accessToken"
        );
        const accessToken = tokenResponse.data.access_token;
        // Fetch calendar data for the single listing
        const response = await axios.get(
          `https://api.hostaway.com/v1/listings/${listing?.id}/calendar`,
          {
            params: {
              startDate: formattedStartDate,
              endDate: formattedEndDate,
              includeResources: 1,
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Cache-Control": "no-cache",
            },
          }
        );
        setCalendarData(response.data.result);
      } catch (error) {
        // console.error("Error fetching calendar for the listing:", error);
      } finally {
        setCalendarLoading(false);
      }
    };
    if (listing.id && formattedStartDate && formattedEndDate) {
      fetchCalendarForListing();
    }
  }, [listing.id, formattedStartDate, formattedEndDate]);

  // Check if the selected dates are available
  const isDateAvailable = handleDateAvailability(
    calendarData,
    formattedStartDate,
    formattedEndDate
  );

  // Fetch tax settings
  useEffect(() => {
    const fetchTaxSettings = async () => {
      try {
        const settings = await fetchListingTaxSettings(listing?.id);
        setTax(settings || []); // Ensure tax is an array
      } catch (error) {
        // console.error("Failed to fetch listing tax settings:", error.message);
      }
    };

    if (listing?.id) {
      fetchTaxSettings();
    }
  }, [listing?.id]);

  const { cityTourismTax, occupancyTax, vatGstTax } = calculateTaxes(
    tax,
    nights,
    updatedPrice
  );

  const calculateWeeklyDiscount = (nights, price) => {
    if (nights >= 30) {
      return price * 0.2; // 20% monthly discount
    } else if (nights >= 7) {
      return price * 0.1; // 10% weekly discount
    }
    return 0;
  };

  // Calculate prices once when component mounts or dependencies change
  useEffect(() => {
    const calculatePrices = () => {
      const basePrice = updatedPrice || 0;
      const markupPrice = basePrice * 1.05;
      const weeklyDiscount = calculateWeeklyDiscount(nights, basePrice);
      setWeeklyDiscount(weeklyDiscount);

      // Additional costs
      const addCosts =
        (additionalValue || 0) +
        (bedLineFee?.amount || 0) +
        (cleaningFee || 0) +
        (cityTourismTax || 0) +
        (occupancyTax || 0) +
        (vatGstTax || 0);

      let finalPrice = markupPrice - weeklyDiscount;

      // Total with all fees
      const totalWithFees = finalPrice + addCosts;

      setCalculatedPrices({
        markupPrice,
        totalWithFees,
      });

      setTotalPrices(totalWithFees);
    };

    calculatePrices();
  }, [
    updatedPrice,
    additionalValue,
    bedLineFee,
    cleaningFee,
    cityTourismTax,
    occupancyTax,
    vatGstTax,
    discount,
    weeklyDiscount,
    nights,
  ]);

  const addsOnServicesString = selectedServices
    ?.map((service) => `${service.label}: AED ${service.value}`)
    .join(", ");
  const additionalServicesString = selectedServices2
    ?.map((service) => `${service.label}`)
    .join(", ");

  const checkAvailability = async () => {
    setIsAvailabilityChecking(true);
    try {
      const tokenResponse = await axios.post(
        "https://365luxuryhomes.com/api/accessToken"
      );
      const accessToken = tokenResponse.data.access_token;

      const availabilityResponse = await axios.get(
        `https://api.hostaway.com/v1/listings/${listing?.id}/calendar`,
        {
          params: {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            includeResources: 1,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Cache-Control": "no-cache",
          },
        }
      );

      const dates = availabilityResponse?.data?.result || [];
      const isBooked = dates?.some((date) => date?.isBooked);

      if (isBooked) {
        throw new Error("Selected dates are no longer available");
      }

      return true;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
      return false;
    } finally {
      setIsAvailabilityChecking(false);
    }
  };

  const handleCheckout = async (e) => {
    e.preventDefault();
    setIsPaymentProcessing(true);
    setPaymentError(null);

    try {
      // Check availability first
      const isAvailable = await checkAvailability();
      if (!isAvailable) {
        setPaymentError("Selected dates are no longer available");
        return;
      }

      // Continue with existing payment logic
      const form = e.target;

      const fname = form.fname.value;
      const lname = form.lname.value;
      const phone = form.phone.value;
      const email = form.email.value;
      const message = form.message.value;

      const userData = {
        fname,
        lname,
        email,
        phone,
        message,
      };

      const reservationData = {
        channelId: 2017,
        listingMapId: listing?.listingSettings?.listingMapId || 0,
        isManuallyChecked: 0,
        isInitial: 0,
        guestName: userData.fname + " " + userData.lname,
        guestFirstName: userData.fname,
        guestLastName: userData.lname,
        guestEmail: userData.email,
        guestPicture: userData?.picture || "",
        numberOfGuests: guestCount || 1,
        adults: guestCount || 1,
        children: childCount || 0,
        infants: infantCount || 0,
        pets: null,
        arrivalDate: formattedStartDate,
        departureDate: formattedEndDate,
        checkInTime: null,
        checkOutTime: null,
        phone: userData.phone,
        totalPrice: totalPrices,
        cleaningFee: cleaningFee || null,
        currency: "AED",
        comment: userData?.comment || "",
        customFieldValues: [
          {
            customFieldId: 71097,
            value: addsOnServicesString,
          },
          {
            customFieldId: 70771,
            value: additionalServicesString,
          },
        ],
      };

      if (
        reservationData.guestName === undefined &&
        userData.guestEmail === undefined
      ) {
        toast.warn("Please provide your information");
        return;
      }

      if (!stripe || !elements) {
        setPaymentError("Stripe is not initialized yet!");
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (error) {
        setPaymentError(error.message);
        return;
      }

      // Process payment with final calculated price
      const paymentResponse = await axios.post(
        "https://365luxuryhomes.com/api/stripe/payment",
        {
          paymentMethodId: paymentMethod.id,
          amount: calculatedPrices.totalWithFees,
          userEmail: form.email.value,
          reservationData: {
            ...reservationData,
            totalPrice: calculatedPrices.totalWithFees,
          },
        }
      );

      if (!paymentResponse.data.success) {
        throw new Error(paymentResponse.data.message || "Payment failed.");
      }
      setMsgtype("success");
      setMessage("Payment successful! Reservation confirmed.");
      toast.success("Payment successful! Reservation confirmed.");
      // Step 3: Remove discount from local storage
      localStorage.removeItem("discount");
    } catch (error) {
      console.error("Error during payment or reservation:", error);

      // Handling various error types
      if (error.response) {
        setMessage(error.response.data.message || "Something went wrong.");
      } else if (error.request) {
        setMessage("Network error. Please check your connection.");
      } else {
        setMessage(error.message || "An unexpected error occurred.");
      }
      setMsgtype("failed");
    } finally {
      setIsPaymentProcessing(false);
    }
  };

  useEffect(() => {
    if (listing?.id) {
      // Calculate base price and all fees
      const basePrice = updatedPrice;
      const additionalCosts =
        additionalValue +
        bedLineFee?.amount +
        cleaningFee +
        cityTourismTax +
        occupancyTax +
        vatGstTax;

      // Apply markup and discounts
      const priceWithMarkup = basePrice * 1.05;
      let finalPrice;

      if (discount && discount > 0) {
        finalPrice = priceWithMarkup - discount + additionalCosts;
      } else {
        finalPrice = priceWithMarkup + additionalCosts;
      }

      setTotalPrices(finalPrice);
    }
  }, [
    listing,
    discount,
    updatedPrice,
    additionalValue,
    bedLineFee,
    cleaningFee,
    cityTourismTax,
    occupancyTax,
    vatGstTax,
  ]);

  const generateTitle = () => {
    if (nights >= 7 && nights < 30) {
      return "Weekly Discount";
    } else if (nights >= 30) {
      return "Monthly Discount";
    }
  };

  return (
    <section className="py-20">
      <div className="container">
        <form
          onSubmit={handleCheckout}
          className="lg:p-8 md:p-6 p-4 bg-gray-50"
        >
          <h1 className="text-2xl font-bold mb-6 text-[#aca9a5]">
            365 Luxury Homes Checkout
          </h1>

          <div className="mb-6">
            <h2 className="text-sm font-semibold text-[#aca9a5] mb-2">
              Booking Summary
            </h2>
            {!isDateAvailable && !calendarLoading && (
              <p className="text-red-500 text-md mb-2">
                Sorry, the check-in date you selected is no longer available.
                Please choose a different date to continue your booking.{" "}
                <Link to={`/listing/${ref}`}>here</Link>
              </p>
            )}
            <table className="w-full border-collapse bg-white shadow rounded">
              <thead>
                <tr className="">
                  <th className="text-left border-b p-2 text-[#aca9a5] text-[14px]">
                    Details
                  </th>
                  <th className="text-right border-b p-2 text-[#aca9a5] text-[14px]">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">Guests</td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    {guestCount || 0} Adults, {childCount || 0} Children,{" "}
                    {infantCount || 0} Infants
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">Start Date</td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    {formattedStartDate}
                  </td>
                </tr>

                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">End Date</td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    {formattedEndDate}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">
                    Total Nights
                  </td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    {nights} Nights
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">Base Price</td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    AED {calculatedPrices?.markupPrice?.toFixed(2) || "0.00"}
                  </td>
                </tr>
                {weeklyDiscount > 0 && (
                  <tr>
                    <td className="p-2 text-red-500 text-[14px]">
                      {generateTitle()}
                    </td>
                    <td className="p-2 text-right text-red-500 text-[14px]">
                      - AED {weeklyDiscount?.toFixed(2) || "0.00"}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">Stay fee</td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    AED{" "}
                    {(cleaningFee + bedLineFee.amount + occupancyTax).toFixed(
                      2
                    ) || "0.00"}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">
                    Add-ons services
                  </td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    AED {additionalValue}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">
                    City / Tourism tax
                  </td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    AED {(cityTourismTax || 0)?.toFixed(2) || "0.00"}
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[#aca9a5] text-[14px]">
                    VAT / GST 5 % (per reservation)
                  </td>
                  <td className="p-2 text-right text-[#aca9a5] text-[14px]">
                    AED {(vatGstTax || 0)?.toFixed(2) || "0.00"}
                  </td>
                </tr>
                {discount && discount > 0 && (
                  <tr className="border-t-[1px]">
                    <td className="p-2 text-[#aca9a5] text-[16px]">
                      Coupon Applied
                    </td>
                    <td className="p-2 text-right text-[#aca9a5] text-[16px]">
                      - AED {(discount || 0)?.toFixed(2) || "0.00"}
                    </td>
                  </tr>
                )}
                <tr className="border-t-[1px]">
                  <td className="p-2 text-[#aca9a5] text-[16px] font-semibold">
                    Subtotal
                  </td>
                  <td className="p-2 text-right text-[#aca9a5] text-[16px] font-semibold">
                    AED {calculatedPrices?.totalWithFees?.toFixed(2) || "0.00"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="bg-white p-6 shadow rounded mb-6">
            <h2 className="text-sm font-semibold mb-4 text-[#aca9a5]">
              Billing details
            </h2>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  placeholder="First name"
                  name="fname"
                  className="border p-2 rounded w-full text-[#aca9a5] focus:ring-[#aca9a5] focus:border-[#aca9a5] text-[14px]"
                  required
                />
                <input
                  type="text"
                  placeholder="Last name"
                  name="lname"
                  className="border p-2 rounded w-full text-[#aca9a5] focus:ring-[#aca9a5] focus:border-[#aca9a5] text-[14px]"
                  required
                />
              </div>
              <input
                type="text"
                placeholder="Phone"
                name="phone"
                className="border p-2 rounded w-full text-[#aca9a5] focus:ring-[#aca9a5] focus:border-[#aca9a5] text-[14px]"
                required
              />
              <input
                type="email"
                placeholder="Email address"
                name="email"
                className="border p-2 rounded w-full text-[#aca9a5] focus:ring-[#aca9a5] focus:border-[#aca9a5] text-[14px]"
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="bg-white p-6 shadow rounded">
              <h3 className="text-sm font-semibold text-[#aca9a5] mb-4">
                Additional information
              </h3>
              <textarea
                placeholder="Tell us something about your booking and stay..."
                name="message"
                className="border p-2 rounded w-full text-[#aca9a5] text-[14px] focus:ring-[#aca9a5] focus:border-[#aca9a5] h-[120px]"
              ></textarea>
            </div>

            <div className="bg-white p-6 shadow rounded">
              <h3 className="text-sm font-semibold mb-4 text-[#aca9a5]">
                Payment Information
              </h3>
              <div className="space-y-4">
                <CardElement className="border p-2 rounded w-full text-[#aca9a5] text-[14px] focus:ring-[#6c675b] focus:border-yellow-500" />

                <button
                  type="submit"
                  disabled={!isDateAvailable || isPaymentProcessing}
                  className="bg-[#aca9a5] text-white font-normal py-2 px-4 rounded hover:bg-[#6c675b] flex items-center gap-2"
                >
                  {loading ? (
                    <>
                      Processing
                      <span className="loading loading-spinner loading-sm"></span>
                    </>
                  ) : (
                    "Confirm Booking"
                  )}
                </button>
                {message && (
                  <p
                    className={`${
                      msgtype === "failed" ? "text-red-500" : "text-green-500"
                    } text-[14px] mt-4`}
                  >
                    {message}
                  </p>
                )}
                {paymentError && (
                  <div className="text-red-500 text-sm mt-2">
                    {paymentError}
                  </div>
                )}
                {isPaymentProcessing && (
                  <div className="text-center py-4">
                    <span className="loading loading-spinner loading-lg"></span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Checkout;
