import React, { Suspense, useContext, useEffect, useState } from "react";
import PropertiesMap from "../../Components/PropertiesMap";
import { DataContext } from "../../Context/Context";
import axios from "axios";
import "./style.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { eachDayOfInterval, subDays } from "date-fns";
import { Link } from "react-router-dom";
import DynamicSeoPage from "../DynamicSeoPage";

const formatPrice = (price) => {
  if (!price) return "N/A";
  return price.toLocaleString("en-AE", {
    style: "currency",
    currency: "AED",
    minimumFractionDigits: 0,
  });
};

const PropertiesOnMap = () => {
  const { startDate, endDate, setEndDate, setStartDate } =
    useContext(DataContext);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [calendarData, setCalendarData] = useState({});

  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const fetchListingsAndCalendar = async () => {
      setLoading(true);
      try {
        const tokenResponse = await axios.post(
          "https://365luxuryhomes.com/api/accessToken"
        );
        const accessToken = tokenResponse.data.access_token;

        const apiUrl = "https://api.hostaway.com/v1/listings";
        const params = {
          availabilityDateStart: formatDate(startDate),
          availabilityDateEnd: formatDate(endDate),
        };

        const listingsResponse = await axios.get(apiUrl, {
          params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const listingsData = listingsResponse?.data?.result || [];
        setListings(listingsData);

        // Fetch calendar data for each listing
        const calendarPromises = listingsData.map(async (listing) => {
          const response = await axios.get(
            `https://api.hostaway.com/v1/listings/${listing.id}/calendar`,
            {
              params: {
                startDate: formatDate(startDate),
                endDate: formatDate(endDate),
                includeResources: 1,
              },
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Cache-Control": "no-cache",
              },
            }
          );
          return { [listing.id]: response.data.result };
        });

        const calendarResults = await Promise.all(calendarPromises);
        const calendarObj = Object.assign({}, ...calendarResults);
        setCalendarData(calendarObj);
      } catch (error) {
        // console.error("Error fetching listings and calendar:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchListingsAndCalendar();
  }, [startDate, endDate]);

  const calculateTotalPrice = (calendar, dateRange) => {
    if (!calendar || !dateRange) return 0;

    return dateRange.reduce((total, date) => {
      const day = calendar.find((item) => item.date === date);
      if (day && day.isAvailable) {
        return total + (day.price || 0);
      }
      return total;
    }, 0);
  };

  const getStartDatePrice = (calendar, startDate) => {
    if (!calendar || !startDate) return null;

    const day = calendar.find((item) => item.date === startDate);
    return day && day.isAvailable ? day.price : null;
  };

  const dateRange =
    startDate && endDate
      ? eachDayOfInterval({
          start: new Date(startDate),
          end: subDays(new Date(endDate), 1),
        }).map((date) => formatDate(date))
      : [];

  const handleChange = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <section className="lg:pt-24 md:pt-24 pt-24 pb-10">
      <DynamicSeoPage page="listings" />
      {loading ? (
        <div className="container">
          <div className="skeleton h-96 w-full flex items-center justify-center">
            <span className="loading loading-ring loading-lg"></span>
          </div>
        </div>
      ) : (
        <>
          <div className="mb-8">
            <div className="container flex items-center justify-between ">
              <div className="flex items-center gap-1">
                <span className="lg:text-xl md:text-[18px] text-[16px] font-medium leading-none text-[#aca9a5]">
                  {listings?.length}
                </span>
                <span className="lg:text-xl md:text-[18px] text-[16px] font-medium leading-none text-[#aca9a5]">
                  Properties found
                </span>
              </div>
              <Link
                to={"/properties"}
                onClick={handleChange}
                className="px-6 py-2 text-[15px] bg-[#aca9a5] hover:bg-[#6c675b] text-white rounded-[4px] hover:text-white focus:text-white active:text-white hover:no-underline"
              >
                Close Map
              </Link>
            </div>
          </div>
          <div className="container px-[20px] flex lg:flex-row md:flex-col-reverse flex-col-reverse items-start gap-8">
            <div className="lg:w-[50%] md:w-full w-full flex flex-col gap-5">
              {listings.map((listing) => (
                <Link
                  key={listing.id}
                  to={`/listing/${listing.id}`}
                  className="flex lg:flex-row md:flex-row flex-col lg:gap-5 md:gap-4 gap-2 items-start hover:no-underline focus:no-underline focus:text-[#aca9a5] active:text-[#aca9a5]"
                >
                  <div className="lg:w-[50%] md:w-[50%] w-full">
                    <Swiper
                      navigation={true}
                      autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                      }}
                      modules={[Navigation, Autoplay]}
                      className="mySwiper w-full h-60 rounded-md overflow-hidden"
                    >
                      {listing?.listingImages?.map((image) => (
                        <SwiperSlide key={image?.id}>
                          <img
                            src={image?.url}
                            alt=""
                            loading="lazy"
                            className="w-full h-full object-cover"
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="lg:w-[50%] md:w-[50%] w-full">
                    <h2 className="text-[18px] leading-[24px] lg:mt-2 md:mt-2 mt-0 font-medium text-[#aca9a5]">
                      {listing?.name}
                    </h2>
                    <div className="flex items-center gap-4 my-2">
                      <div className="flex gap-1 items-center">
                        <span className="text-[15px] font-normal text-[#aca9a5]">
                          {listing?.personCapacity}
                        </span>
                        <span className="text-[15px] font-normal text-[#aca9a5]">
                          Guests
                        </span>
                      </div>
                      <div className="flex gap-1 items-center">
                        <span className="text-[15px] font-normal text-[#aca9a5]">
                          {listing?.bedroomsNumber}
                        </span>
                        <span className="text-[15px] font-normal text-[#aca9a5]">
                          Bedrooms
                        </span>
                      </div>
                      <div className="flex gap-1 items-center">
                        <span className="text-[15px] font-normal text-[#aca9a5]">
                          {listing?.bathroomsNumber}
                        </span>
                        <span className="text-[15px] font-normal text-[#aca9a5]">
                          Bathrooms
                        </span>
                      </div>
                    </div>
                    {startDate && endDate && (
                      <div>
                        <div className="text-[18px] font-medium text-[#aca9a5] my-2">
                          {formatPrice(
                            getStartDatePrice(
                              calendarData[listing.id],
                              formatDate(startDate)
                            )
                          ) || "N/A"}
                          /night
                        </div>
                        <div className="text-[15px] font-light text-[#aca9a5] my-2">
                          {formatPrice(
                            calculateTotalPrice(
                              calendarData[listing.id],
                              dateRange
                            ) * 1.05
                          ) || "N/A"}{" "}
                          total
                        </div>
                      </div>
                    )}
                    <div className="flex flex-wrap gap-2">
                      {listing?.listingAmenities?.slice(0, 5).map((amenity) => (
                        <span
                          key={amenity?.id}
                          className="px-[6px] py-[3px] border border-[#aca9a5] text-[#aca9a5] text-[14px] rounded-full"
                        >
                          {amenity?.amenityName}
                        </span>
                      ))}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <div className="lg:w-[50%] md:w-full w-full sticky-section rounded-md overflow-hidden">
              <Suspense
                fallback={<div className="skeleton h-full w-full"></div>}
              >
                <PropertiesMap listings={listings} />
              </Suspense>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default PropertiesOnMap;
